import React, { useState, useEffect } from 'react'
import axios from "axios"
import tw, { css, styled } from 'twin.macro'

import NotificationModal from './NotificationModal';

const NewsLetterWrapper = styled.section`
  background-color: ${props => props.color === "white" ? "#fff" : "#e9f8ff"};
`;

const NewsLetterContainer = styled.div`
  ${tw`text-center lg:text-left lg:flex justify-between max-w-screen-xl mx-auto items-center pt-16 px-5 sm:px-10 lg:px-20 xl:px-51`};
`

const AlertContainer = tw.div`max-w-screen-xl mx-auto px-5 pt-5 pb-25 flex justify-center text-xl text-brand-dark text-center font-semibold`

const NewsLetterTitle = tw.h3`mb-8 lg:mb-0 font-mulish font-extrabold text-brand-dark-200 text-7/2 sm:text-9/2 leading-tight lg:w-newsletter-title`

const NewsLetterForm = styled.form`
  ${tw`mx-auto lg:mx-0 sm:w-7/10 lg:w-auto sm:flex`};
  filter: drop-shadow(0px 10.6465px 30px rgba(37, 74, 196, 0.15));
`

const NewsLetterLabel = styled.label`
  ${tw`sm:w-7/10 lg:w-auto relative`}
  background-color: white !important;
  :before {
    position: absolute;
    content: '';
    width: 30px;
    height: 30px;
    top: 22px;
    left: 32px;
    background: url("data:image/svg+xml,%3Csvg width='30' height='30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M27.188 3.75H2.813A2.816 2.816 0 000 6.563v16.875a2.816 2.816 0 002.813 2.812h24.375A2.816 2.816 0 0030 23.437V6.564a2.816 2.816 0 00-2.813-2.813zm0 1.875c.127 0 .248.026.359.073L15 16.572 2.453 5.698a.933.933 0 01.36-.073h24.374zm0 18.75H2.813a.938.938 0 01-.938-.938V7.68l12.51 10.842a.936.936 0 001.23 0L28.124 7.68v15.758c0 .518-.42.938-.938.938z' fill='%23143152'/%3E%3C/svg%3E") center / contain no-repeat;
  }

  @media (max-width: 639.98px) {
    :before {
      top: 2px;
      width: 21px;
      height: 21px;
      left: 16px;
    }
  }
`

const NewsLetterInput = styled.input`
  ${tw`mb-12 sm:mb-0 w-full lg:w-newsletter-input border-0 rounded pl-12 sm:pl-20 pr-8 py-6 font-mulish italic text-brand-dark-300 tracking-brand outline-none`}

  background-color: white !important;
  &:focus {
    background-color: white !important;
  }
`

const NewsLetterSubmit = styled.button`
  ${tw`xs:mx-auto xs:px-6 xs:py-4 sm:w-3/10 lg:w-newsletter-btn bg-brand-blue text-white font-lato flex items-center justify-center border-0 outline-none`}
  font-size: 23.0769px;
  line-height: 29px;
  border-radius: 0 4px 4px 0;
  
  &:focus {
    outline: 0;
  }

  @media (max-width: 639px) {
    border-radius: 4px;
  }
`

const RelativeContainer = tw.div`relative`;

const ValidationText = styled.p`
  ${tw`font-lato text-left py-2`}
  color: red;
  font-size: 16px;

  @media only screen and (max-width: 1023.98px) {
    width: 70%;
    margin: auto;
  }

  @media only screen and (max-width: 639.98px) {
    width: 100%;
    position: absolute;
    top: 71px;
  }
`

const Failed = styled.p`
  ${tw`font-lato text-center`}
  color: red;
  font-size: 16px;
  display: none;

  ${({ show }) =>
    show &&
    css`
      display: block;
    `
  }
`


const NewsLetter = props => {
  const [modal, setModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [timeLeft, setTimeLeft] = useState(null);
  const [failed, showFailed] = useState(false)
  const [fieldErrors, setFieldErrors] = useState({});


  const [input, setInput] = useState({
    email: "",
  })

  const handleChange = event => {
    setInput({
      email: event.target.value
    })
  }

  const validationRules = {
    email: val => val && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(val)
  };

  const validate = () => {
    let errors = {};
    let hasErrors = false;
    for (let key of Object.keys(input)) {
      errors[key] = !validationRules[key](input[key]);
      hasErrors |= errors[key];
    }
    setFieldErrors(prev => ({ ...prev, ...errors }));
    return !hasErrors;
  };

  const renderFieldError = field => {
    if (fieldErrors[field]) {
      return <ValidationText>Please enter a valid {field}.</ValidationText>;
    }  
  }

  const closeModal = () => {
    setModal(false);
    setModalMessage("");
  }

  const [serverState, setServerState] = useState({
    submitting: false,
    status: null
  });



  const handleServerResponse = (ok, msg) => {
    setFieldErrors({});
    setServerState({
      submitting: false,
      status: { ok, msg }
    });

    if (ok) {
      setInput({
        email: ""
      })
      setTimeLeft(5)
      setModal(true)
      setModalMessage("Thank you for subscribing to our newsletter");

      setTimeout(() => {
        setModal(false);
        setModalMessage("")
      }, 5000)

    }

    if (!ok) {
      showFailed(true)
      setTimeout(() => {
        showFailed(false)
      }, 5000)
    }

  };
  const handleSubmit = event => {
    event.preventDefault();
    if (!validate()) {
      return;
    }

    setServerState({ submitting: true });
    axios({
      method: "POST",
      url: "https://formspree.io/f/xzbygkjz",
      data: input
    })
    .then(r => {
      handleServerResponse(true, "Thanks!");
    })
    .catch(r => {
      handleServerResponse(false, r.response.data.error);
    });
  };

  useEffect(() => {
    if (Object.keys(fieldErrors).length > 0) {
      validate();
    }
  }, [input]);

  useEffect(() => {
    if (modal) {

      if (!timeLeft) return;

      const intervalId = setInterval(() => {
        setTimeLeft(timeLeft - 1)
      }, 1000);

      return () => clearInterval(intervalId)
    }

  },[modal, timeLeft]);

  return (
    <NewsLetterWrapper color={props.color}>
      <NewsLetterContainer>
        <NewsLetterTitle>Sign up for our newsletter</NewsLetterTitle>

        {
          modal && (
            <NotificationModal active={modal} successMessage={modalMessage} closeModal={closeModal} time={timeLeft}/>
          )
        }
        <RelativeContainer>
          <NewsLetterForm onSubmit={handleSubmit}>
            <NewsLetterLabel>
              <NewsLetterInput type="text" placeholder="Your email address" name="Email Address" value={input.email} onChange={handleChange}/>
            </NewsLetterLabel>
            <NewsLetterSubmit type="submit" disabled={serverState.submitting}>Subscribe</NewsLetterSubmit>
          </NewsLetterForm>
          {renderFieldError("email")}
        </RelativeContainer>
        
      </NewsLetterContainer>
      <AlertContainer>
        {
          failed && (
            <Failed show={failed}>{serverState.status.msg}</Failed>
          )
        }
      </AlertContainer>
      
    </NewsLetterWrapper>
  )
}

export default NewsLetter