import React from 'react'

import tw, { styled } from 'twin.macro'


import ShellLogo from '../assets/images/shell.png'
import ChevronLogo from '../assets/images/chevron.png'
import AiteoLogo from '../assets/images/aiteo.png'
import DangoteLogo from '../assets/images/dangote.png'
import ExxonMobilLogo from '../assets/images/exxon-mobil.png'
import TotalLogo from '../assets/images/total-logo.jpg'
import LngLogo from '../assets/images/lng-logo.jpg'

const PartnersWrapper = styled.div`
  background-color: ${props => props.color === "blue" ? "#f5fafd" : "#fff"};
`
const PartnersSection = styled.section`
  ${tw`max-w-screen-xl mx-auto py-13 md:pt-28 md:pb-14 px-5 sm:px-10 lg:px-14 xl:px-28`}
`

const PartnersContainer = tw.div`text-center lg:text-left lg:flex justify-between items-center mb-3`

const PartnersTitle = tw.h3`mb-4 font-mulish font-extrabold text-brand-dark tracking-brand text-7/2 md:text-9/2 leading-tight capitalize lg:mb-0`

const PartnersText = tw.p`lg:w-partners-text font-lato text-xl leading-brand-small opacity-60 text-brand-dark-200`

// const PartnersLogoBox = styled.section`
//   ${tw`xs:mt-10 flex-wrap justify-center px-4 big-tablet:flex-nowrap big-tablet:justify-start xs:flex-col flex items-center big-tablet:px-8`}

//   > *:not(:last-child) {
//     ${tw`lg:mr-41/2 md:mr-12 xs:mb-4 sm:mr-8`}
//   }
// `

const PartnersLogoBox = styled.section`
  ${tw`grid grid-cols-1 gap-y-8 sm:gap-y-0 sm:grid-cols-7 sm:gap-x-8 place-items-center mt-20 md:mt-10`}
`

const PartnerLogo = tw.img`xs:block sm:inline-block`

const Partners = props => {
  return (
    <PartnersWrapper color={props.color}>
      <PartnersSection>
        <PartnersContainer>
          <PartnersTitle>Trusted by Multinationals</PartnersTitle>
          <PartnersText>Building strong Local Capacity in Nigeria through Strategic Partnerships, Certifications and Affiliations.</PartnersText>
        </PartnersContainer>
        <PartnersLogoBox>
          <PartnerLogo src={ShellLogo} alt="Shell" />
          <PartnerLogo src={DangoteLogo} alt="Dangote" />
          <PartnerLogo src={ExxonMobilLogo} alt="Exxon Mobil" /> 
          <PartnerLogo src={ChevronLogo} alt="Chevron" />
          <PartnerLogo src={AiteoLogo} alt="Aiteo" />
          <PartnerLogo src={TotalLogo} alt="Total" />
          <PartnerLogo src={LngLogo} alt="LNG" />
        </PartnersLogoBox>
      </PartnersSection>
    </PartnersWrapper>
  )
}

export default Partners